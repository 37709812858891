import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element: Component }) => {
  const isUserLoggedIn = () => {
    const userName = localStorage.getItem("userName");
    const email = localStorage.getItem("userEmail");
    const id = localStorage.getItem("id");
    return userName && email && id;
  };

  return isUserLoggedIn() ? Component : <Navigate to="/signin" />;
};

export default PrivateRoute;
