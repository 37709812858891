import React from 'react';
import visionImage from "../images/vision.png"; 

const OurVisionSection = () => (
  <div className="">
    <div className="container mx-auto flex flex-col lg:flex-row items-center">
      <div className="lg:w-1/2 lg:pr-12">
        <img src={visionImage} alt="Our Vision" className="w-full rounded-lg shadow-md" />
      </div>
      <div className="lg:w-1/2 lg:pl-12 mt-8 lg:mt-0 ">
        <h1 className="text-4xl font-bold mb-12 text-white">Our Vision</h1>
        <p className="text-lg text-gray-200 mb-4">
          Our vision is to revolutionize the way people search for information online. By integrating multiple search engines into a single platform, we aim to provide users with the most comprehensive and accurate search results available.
        </p>
        <p className="text-lg text-gray-200 mb-4">
          We believe in the power of information and strive to make it accessible to everyone, regardless of their technical expertise. Our user-friendly interface and advanced algorithms ensure that you get the best possible search experience every time.
        </p>
        <p className="text-lg text-gray-200 mb-4">
          Join us on our journey to transform online search, making it faster, easier, and more efficient. Together, we can change the way the world finds information.
        </p>
      </div>
    </div>
  </div>
);

export default OurVisionSection;
