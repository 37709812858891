import React, { useState, useEffect, useCallback, useMemo } from "react";
import "animate.css";
import TrackVisibility from "react-on-screen";
import logo from "../images/homeImage.png"; // Assuming logo.jpg is the image to be displayed
import { Link } from "react-router-dom";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 50);
  const period = 1000;

  const toRotate = useMemo(() => ["Youtube", "Google", "Yahoo", "Bing", "DuckDuckGo"], []);

  const tick = useCallback(() => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(100);
    }
  }, [isDeleting, loopNum, text.length, toRotate]);

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [delta, tick]);

  const renderSearchEngineLogo = () => {
    return <img src={logo} alt="Logo" className="h-full w-full" />;
  };

  return (
    <section className="banner container mx-auto py-20 px-4">
      <div className="flex flex-col md:flex-row items-center justify-between">
        {/* Text Content */}
        <TrackVisibility>
          {({ isVisible }) => (
            <div
              className={`${
                isVisible ? "animate__animated animate__fadeIn" : ""
              } w-full md:w-2/3 text-center md:text-left mb-8 md:mb-0`}
            >
              <h1 className="text-3xl md:text-4xl font-bold mt-4 mb-6 text-white">
                Discover Your Perfect Search Engine{" "}
                <span
                  className="txt-rotate"
                  data-period="1000"
                  data-rotate='["Youtube", "Google", "Yahoo", "Bing", "DuckDuckGo"]'
                >
                  <span className="wrap text-textSoft">{text}</span>
                </span>
              </h1>
              <p  className="text-white mb-8 leading-relaxed">
                Welcome to the ultimate Search Engine Finder! Whether you're
                looking for videos on Youtube, reliable information on Google,
                the latest news on Yahoo, comprehensive results on Bing, or
                privacy-focused searches on DuckDuckGo, we've got you covered.
                Explore the best search engines tailored to your needs and
                preferences.
              </p>
              <Link to="/home">
                <button className="bg-textSoft text-bgDark border font-medium border-textSoft hover:bg-bgSoft hover:text-textSoft px-6 py-3 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50">
                  GET STARTED
                </button>
              </Link>

              {/* <Link to="/tiktokhome">
                <button className="bg-textSoft ml-6 text-bgDark border font-medium border-textSoft hover:bg-bgSoft hover:text-textSoft px-6 py-3 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50">
                  TIKTOK HOME
                </button>
              </Link> */}
            </div>
          )}
        </TrackVisibility>

        {/* Search Engine Logo */}
        <TrackVisibility>
          {({ isVisible }) => (
            <div
              className={`${
                isVisible ? "animate__animated animate__zoomIn" : ""
              } w-full flex justify-center items-center`}
            >
              {renderSearchEngineLogo()}
            </div>
          )}
        </TrackVisibility>
      </div>
    </section>
  );
};
