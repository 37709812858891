import React from 'react'
import { Banner } from '../components/Banner'
import Navbar from '../components/Navbar'
import HowItWorks from '../components/HowItWorks'
import FAQSection from '../components/FAQSection'
import FeaturesList from '../components/WhyChooseUs'
import OurVisionSection from '../components/OurVision'
import FooterSection from '../components/FooterSection'

const LandingPage = () => {
  return (
    <div className='bg-bgDark text-white'>
        <Navbar/>
        <Banner/>
        <HowItWorks/>
        <FeaturesList/>
        <OurVisionSection/>
        <FAQSection/>
        <FooterSection/>
    </div>
  )
}

export default LandingPage