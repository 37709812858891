import React from "react";

const ResultLimitInput = ({ limit, handleLimitChange }) => (
  <div className="w-full md:w-1/2 mx-auto mt-4 px-4 md:px-0">
    <input
      type="number"
      className="border border-textSoft h-12 rounded-lg p-2 w-full"
      placeholder="Enter number of results (1-30)"
      value={limit}
      onChange={handleLimitChange}
    />
  </div>
);

export default ResultLimitInput;
