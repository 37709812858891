/** @format */

export const faqs = [
  {
    question: "What is a search engine finder?",
    answer: "A search engine finder is a tool that allows you to search multiple search engines simultaneously and get comprehensive results in one place."
  },
  {
    question: "How do I use the search engine finder?",
    answer: "Simply enter your query, select the search engines you want to use, and get comprehensive results from all selected search engines."
  },
  {
    question: "Is my search history tracked?",
    answer: "No, we prioritize your privacy and do not track your search history or store any personal data."
  },
  {
    question: "Is the service free to use?",
    answer: "Yes, our search engine finder is free to use for everyone."
  },
  {
    question: "Can I choose which search engines to use?",
    answer: "Yes, you can select from Google, YouTube, Bing, Yahoo, DuckDuckGo, and more."
  }
];
