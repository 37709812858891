import React, { useEffect, useState } from "react";
import Loader from "../components/Loading";
import { FaDownload } from "react-icons/fa";

const Suggestions = ({ query }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `http://localhost/learnPhp/googlesearch.php?query=${encodeURIComponent(
            query
          )}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        const responseData = await response.json();
        setData(responseData);
        console.log("res", responseData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchSuggestions();
  }, [query]);

  const escapeHTML = (htmlString) => {
    const element = document.createElement("div");
    element.innerHTML = htmlString;
    return element.textContent || element.innerText || "";
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const downloadAsTxt = (question, suggestions) => {
    const content = suggestions.join("\n");
    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${question}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!query || query.length === 0) {
    return <p className="text-white">No questions query found.</p>;
  }

  return (
    <>
      <div className="">
        <div className="container mx-auto py-10">
          <h1 className="text-center text-4xl font-bold mb-12 text-white">
            Questions
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {Object.keys(data).length === 0 ? (
              <p className="text-white">No data to load</p>
            ) : (
              Object.keys(data)
                .filter((question) => {
                  const suggestions = data[question].error
                    ? []
                    : data[question];
                  console.log("segg", suggestions);
                  return Array.isArray(suggestions) && suggestions.length > 0;
                })
                .map((question, index) => (
                  <div
                    key={index}
                    className="bg-white p-6 text-bgDark shadow-md overflow-hidden mb-6 transition-shadow ease-in-out hover:shadow-[0_0_30px_#FFD338] to- hover:text-black rounded-lg duration-300"
                  >
                    <div className="flex justify-between items-center mb-12">
                      <h1 className="text-bgDark text-center text-4xl font-bold">
                        '{capitalizeFirstLetter(question)}'
                      </h1>
                      <button
                        onClick={() =>
                          downloadAsTxt(question, data[question])
                        }
                        className="text-bgDark hover:text-bgSoft border border-bgDark p-2 rounded-md"
                        title="Download"
                      >
                        <FaDownload size={20} />
                      </button>
                    </div>
                    {data[question].error ? (
                      <p>Error: {data[question].error}</p>
                    ) : (
                      <ul>
                        {data[question].map((suggestion, idx) => (
                          <li
                            key={idx}
                            className="text-bgDark border-b border-gray-300 p-2"
                          >
                            {escapeHTML(suggestion)}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Suggestions;
