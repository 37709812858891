import React, { useState, useEffect } from "react";
import SearchInput from "./SearchInput";
import EngineSelect from "./EngineSelect";
import ResultLimitInput from "./ResultLimitInput";
import SearchButton from "./SearchButton";
import LoadingIndicator from "./LoadingIndicator";
import SearchResults from "./SearchResults";
import Suggestions from "./Question";
import TabNavigation from "./TabNavigation";

function MultiSelectDropdown({
  query,
  useProxy,
  setQuery,

  setUseProxy,
}) {
  const defaultEngine = { value: "youtube", label: "Youtube" };

  const [selectedOptions, setSelectedOptions] = useState([defaultEngine]);
  const [activeTabs, setActiveTabs] = useState([defaultEngine.value]);
  const [loading, setLoading] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const [limit, setLimit] = useState(10);
  const [currentStep, setCurrentStep] = useState(0);
  const [viewTab, setViewTab] = useState("results");
  const [results, setResults] = useState({});

  // const [googleTotalResults, setGoogleTotalResults] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);

  const options = [
    { value: "youtube", label: "Youtube", exportable: false },
    { value: "google", label: "Google", exportable: true },
    { value: "bing", label: "Bing", exportable: true },
    { value: "yahoo", label: "Yahoo", exportable: true },
    { value: "duckduckgo", label: "DuckDuckGo", exportable: false },
  ];

  const [activeTab, setActiveTab] = useState(defaultEngine.value);

  const handleChange = (selected) => {
    const selectedEngineValues = selected.map((option) => option.value);
    setSelectedOptions(selected);
    setActiveTabs(selectedEngineValues);
    if (selectedEngineValues.length > 0) {
      setActiveTab(selectedEngineValues[0]); // Set the first selected tab as active
    }
  };


  useEffect(() => {
    if (searchClicked && activeTabs.length > 0) {
      const fetchSearchResults = async (engines, keyword, limit) => {
        setLoading(true);
        const fetchedResults = {};
        const fetchedRelatedData = [];
        const errors = [];
        setCurrentStep(0);

        for (const engine of engines) {
          try {
            const response = await fetch(
              `http://localhost/learnPhp/${engine}data.php?keyword=${encodeURIComponent(
                keyword
              )}&useProxy=${useProxy}&limit=${limit}`
            );

            if (!response.ok) {
              throw new Error(`HTTP error: ${response.status}`);
            }

            const data = await response.json();
            fetchedResults[engine] = data.results;

            if (engine === "google") {
              fetchedRelatedData.push(...data.peopleAlsoSearchFor);
              // setGoogleTotalResults(data.totalResults);
            }
          } catch (error) {
            console.error(`Error fetching data from ${engine}:`, error);
            errors.push(`Error fetching data from ${engine}`);
            fetchedResults[engine] = [];
          }

          setCurrentStep((prev) => prev + 1);
        }

        setResults(fetchedResults);

        setErrorMessages(errors);
        setLoading(false);
        setSearchClicked(false);
      };

      fetchSearchResults(activeTabs, query, limit);
    }
  }, [searchClicked, activeTabs, limit, query, useProxy, setResults]);

  const handleSearchClick = () => {
    if (query && activeTabs.length > 0) {
      setSearchClicked(true);
    } else {
      alert("Please enter a query and select at least one search engine.");
    }
  };

  const handleLimitChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 1 && value <= 30) {
      setLimit(value);
    }
  };

  const handleProxyChange = (event) => {
    setUseProxy(event.target.checked);
  };

  const handleViewTabChange = (tab) => {
    setViewTab(tab);
  };

  return (
    <div className="text-center">
      <div>
        <SearchInput query={query} setQuery={setQuery} />

        <EngineSelect
          selectedOptions={selectedOptions}
          handleChange={handleChange}
          options={options}
        />

        <div className="w-1/2 mx-auto text-left my-4">
          <input
            type="checkbox"
            id="useProxy"
            checked={useProxy}
            onChange={handleProxyChange}
            className="mr-2"
          />
          <label htmlFor="useProxy" className="text-white">
            Use Proxy
          </label>
        </div>

        <ResultLimitInput limit={limit} handleLimitChange={handleLimitChange} />

        <SearchButton handleSearchClick={handleSearchClick} />

        <div className="my-4">
          <button
            className={`bg-bgDark border-2 border-textSoft px-6 py-3 font-medium rounded-md shadow-lg hover:bg-bgSoft focus:outline-none focus:ring-2 focus:ring-textSoft focus:ring-opacity-50 mr-2 ${
              viewTab === "results"
                ? "text-bgDark bg-textSoft hover:bg-textSoft hover:text-[#0d0c22]"
                : "text-textSoft"
            }`}
            onClick={() => handleViewTabChange("results")}
          >
            View Results
          </button>
          <button
            className={`bg-bgDark border-2 border-textSoft  px-6 py-3 font-medium rounded-md shadow-lg hover:bg-bgSoft focus:outline-none focus:ring-2 focus:ring-textSoft focus:ring-opacity-50 mr-2 ${
              viewTab === "questions"
                ? "bg-textSoft text-bgDark hover:bg-textSoft hover:text-[#0d0c22]"
                : "text-textSoft"
            }`}
            onClick={() => handleViewTabChange("questions")}
          >
            View Questions
          </button>
        </div>
      </div>

      <div className="mt-4">
        {loading ? (
          <div className="py-10">
            <LoadingIndicator
              activeTabs={activeTabs}
              currentStep={currentStep}
            />
          </div>
        ) : (
          <>
            {viewTab === "results" && (
              <div>
                {Object.keys(results).length > 0 && (
                  <div className=" text-center mt-8">
                    <TabNavigation
                      activeTabs={Object.keys(results)}
                      activeTab={activeTab}
                      handleTabClick={setActiveTab}
                      options={options}
                    />
                    <SearchResults
                      engine={
                        options.find((option) => option.value === activeTab)
                          ?.label || ""
                      }
                      results={results[activeTab]}
                      errorMessages={errorMessages}
                    />
                  </div>
                )}
              </div>
            )}

            {viewTab === "questions" && (
              <div>
                <Suggestions query={query} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default MultiSelectDropdown;
