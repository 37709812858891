import React from "react";

const SearchButton = ({ handleSearchClick }) => (
  <div className="w-full md:w-1/2 mx-auto my-4 px-4 md:px-0">
    <button
      className="bg-textSoft text-bgDark px-6 w-full py-3 font-bold rounded-full shadow-lg hover:bg-textSoft focus:outline-none focus:ring-2 focus:ring-textSoft focus:ring-opacity-50"
      onClick={handleSearchClick}
    >
      Search
    </button>
  </div>
);

export default SearchButton;
