import React, { useEffect, useState } from "react";
import { FaHeart, FaDownload } from "react-icons/fa";
import * as XLSX from "xlsx";
import axios from "axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function SearchResults({ engine, results = [], errorMessages=[] }) {
  const [favorites, setFavorites] = useState([]);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    // Retrieve user ID from localStorage
    const storedUserId = localStorage.getItem('id');
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  const handleFavoriteClick = async (event, index) => {
    event.preventDefault();
    const result = results[index];

    const alreadyFavorited = favorites.some(
      (fav) => fav.title === result.title
    );

    if (!alreadyFavorited) {
      try {
        const response = await axios.post(
          "http://localhost/learnPhp/addToFavorites.php",
          {
            engine: engine,
            title: result.title,
            uid: userId,
            description: result.description,
            link: result.link,
          }
        );

        if (response.status === 201) {
          setFavorites([...favorites, result]);
          toast.success("Item added to favorites successfully", {
            position: "top-center",
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          toast.info("This item is already in your favorites", {
            position: "top-center",
          });
        } else {
          toast.error("Failed to add item to favorites", {
            position: "top-center",
          });
        }
      }
    } else {
      toast.info("This item is already in your favorites", {
        position: "top-center",
      });
    }
  };

  const handleExport = () => {
    if (results.length > 0) {
      // Create a worksheet from the data
      const worksheet = XLSX.utils.json_to_sheet(results);
      // Create a workbook and add the worksheet to it
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, `${engine} Results`);
      // Generate a downloadable Excel file
      XLSX.writeFile(workbook, `${engine}_results.xlsx`);
    } else {
      toast.info("No data available for export", {
        position: "top-center",
      });
    }
  };

  return (
    <div className="w-full md:w-3/4 lg:w-1/2 mx-auto pb-10 px-4">
      {/* Display error messages specific to the active tab */}
     

      {/* Display results if available */}
      {results.length > 0 ? (
        <>
          <div className="flex justify-end">
            <button
              className="bg-bgSoft text-textSoft border border-textSoft px-6 py-3 rounded-md shadow-lg hover:bg-bgDark focus:outline-none focus:ring-2 focus:ring-textSoft focus:ring-opacity-50 my-4 flex items-center justify-center"
              onClick={handleExport}
            >
              <FaDownload className="mr-2" />
              Export
            </button>
          </div>

          {results.map((result, index) => (
            <div
              key={index}
              className="p-6 bg-white mt-6 shadow-lg rounded-lg border border-gray-200 relative transition-transform transform hover:scale-105 hover:shadow-2xl"
            >
              {/* Favorite icon */}
              <button
                className={`absolute top-2 right-2 text-gray-400 hover:text-textSoft ${
                  favorites.some((fav) => fav.title === result.title) ? "text-textSoft" : ""
                }`}
                onClick={(event) => handleFavoriteClick(event, index)}
              >
                <FaHeart size={24} />
              </button>

              <h3 className="text-xl font-semibold mb-2 text-bgDark">
                {result.title}
              </h3>
              <p className="text-gray-700 mb-4">{result.description}</p>
              <a
                href={result.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-textSoft bg-bgDark hover:bg-bgSoft px-4 py-2 rounded-full"
              >
                Visit Link
              </a>
            </div>
          ))}
        </>
      ) : (
        <div className="mt-4 text-gray-500">
           {errorMessages.length > 0 && (
        <div className="mt-4">
          {errorMessages.map((msg, index) => (
            <div key={index} className="text-red-500">
              {msg}
            </div>
          ))}
        </div>
      )}
       
        </div>
      )}
    </div>
  );
}

export default SearchResults;
