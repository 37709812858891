import React from "react";
import Stepper from "./StepperLoader";

const LoadingIndicator = ({ activeTabs, currentStep }) => (
  <div className="">
    <p  className="text-white">Please Wait .....</p>
    <div className="w-1/2 mx-auto flex items-center justify-center my-10">
    <div
        className="animate-spin rounded-full border-t-4 border-textSoft border-opacity-100 border-solid h-10 w-10
         md:h-12 md:w-12 z-50"
      ></div>
    </div>
  
    <Stepper steps={activeTabs} currentStep={currentStep} />
  </div>
);

export default LoadingIndicator;
