import React, { useState } from "react";
import Navbar from "../components/Navbar";
import "../App.css";
import MultiSelectDropdown from "../components/MultiSelectDropDown";
import FooterSection from "../components/FooterSection";

const Home = () => {
  const [query, setQuery] = useState("");
  const [useProxy, setUseProxy] = useState(false);

  return (
    <div>
      <Navbar />

      <div className="min-h-screen bg-bgDark">
        <div className="">
          <h1 className="text-4xl font-bold mb-12 text-white text-center py-10">
            Search Results
          </h1>
          <div className="w-[80%] mx-auto text-center">
            <MultiSelectDropdown
              query={query}
              setQuery={setQuery}
              useProxy={useProxy}
              setUseProxy={setUseProxy}
            />
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  );
};

export default Home;
