import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import FooterSection from '../components/FooterSection';

function FavoritesPage() {
  const [favorites, setFavorites] = useState([]);
  const [filteredFavorites, setFilteredFavorites] = useState([]);
  const [activeTab, setActiveTab] = useState(''); // Initialize with empty string
  const [userId, setUserId] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const favoritesPerPage = 5; // Number of favorites to display per page

  useEffect(() => {
    // Fetch userId from localStorage or your authentication context
    const storedUserId = localStorage.getItem('id');
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  useEffect(() => {
    const fetchFavorites = async () => {
      if (!userId) return; // Exit if userId is not set

      try {
        const response = await axios.get(`http://localhost/learnPhp/getFavorites.php?userId=${userId}`);
        setFavorites(response.data);
        // Initialize active tab with the first engine type found
        if (response.data.length > 0) {
          setActiveTab(response.data[0].engine);
        }
      } catch (error) {
        console.error('Error fetching favorites:', error);
      }
    };

    fetchFavorites();
  }, [userId]);

  // Filter favorites based on selected engine
  useEffect(() => {
    if (activeTab !== '') {
      const filtered = favorites.filter(favorite => favorite.engine === activeTab);
      setFilteredFavorites(filtered);
      setCurrentPage(1); // Reset to first page when filter changes
    }
  }, [favorites, activeTab]);

  // Pagination logic
  const indexOfLastFavorite = currentPage * favoritesPerPage;
  const indexOfFirstFavorite = indexOfLastFavorite - favoritesPerPage;
  const currentFavorites = filteredFavorites.slice(indexOfFirstFavorite, indexOfLastFavorite);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  // Handle tab click
  const handleTabClick = (engine) => {
    setActiveTab(engine);
  };

  return (
    <>
      <Navbar />
      <div className='min-h-screen bg-bgDark'>
        <div className="container mx-auto py-10 px-4 sm:px-6 lg:px-8">
          <h1 className="text-center text-4xl font-bold mb-12 text-white">Favorite Items</h1>

          {/* Render tabs */}
          <div className="flex flex-wrap justify-center mb-6">
            {['Youtube', 'Google', 'Yahoo', 'Bing', 'DuckDuckGo'].map((engine) => (
              <button
                key={engine}
                className={`mx-2 px-4 py-2 my-1 rounded-full ${activeTab === engine ? 'bg-textSoft text-bgDark' : 'border border-textSoft text-textSoft'}`}
                onClick={() => handleTabClick(engine)}
              >
                {engine}
              </button>
            ))}
          </div>

          {/* Render favorites based on active tab */}
          <div className="w-full md:w-3/4 lg:w-1/2 mx-auto">
            {currentFavorites.length === 0 ? (
              activeTab === '' ? (
                <p className="text-center text-white">Select a tab to view your favorites</p>
              ) : (
                <p className="text-center text-white">No favorites found for {activeTab}</p>
              )
            ) : (
              currentFavorites.map((favorite, index) => (
                <div
                  key={index}
                  className="p-6 bg-white my-6 shadow-lg border border-gray-200 hover:shadow-[0_0_20px_#FFD338] to- text-white hover:text-black rounded-lg duration-300"
                >
                  <h3 className="text-xl font-semibold mb-2 text-bgDark">{favorite.title}</h3>
                  <p className="text-gray-700 hover:text-bgDark mb-4">{favorite.description}</p>
                  <a
                    href={favorite.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-bgDark border border-bgDark hover:bg-bgDark hover:text-textSoft px-4 py-2 rounded-full"
                  >
                    Visit Link
                  </a>
                </div>
              ))
            )}

            {/* Pagination */}
            {filteredFavorites.length > favoritesPerPage && (
              <div className="flex justify-center my-4">
                {Array.from({ length: Math.ceil(filteredFavorites.length / favoritesPerPage) }, (_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-3 py-1 rounded-full ${currentPage === index + 1 ? 'bg-textSoft text-bgDark' : 'border border-textSoft text-textSoft'}`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <FooterSection />
    </>
  );
}

export default FavoritesPage;
