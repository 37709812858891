import React from 'react';
import { FaSearch, FaClock, FaCheck, FaShieldAlt, FaLayerGroup, FaDollarSign } from 'react-icons/fa';

const features = [
  {
    title: "Easy to Use",
    description: "With a simple and intuitive interface, our platform allows you to quickly input your search query and get results from various search engines. Whether you're a tech-savvy user or a beginner, you'll find our website easy to navigate.",
    icon: <FaSearch />
  },
  {
    title: "Time-Saving",
    description: "Save time by getting all your search results in one place. No need to open multiple tabs or applications. Our platform provides a one-stop solution for all your search needs.",
    icon: <FaClock />
  },
  {
    title: "Accurate and Relevant Results",
    description: "We use advanced scraping techniques and algorithms to fetch the most accurate and relevant results for your queries. Our goal is to provide you with the best possible information, curated from trusted sources.",
    icon: <FaCheck />
  },
  {
    title: "Privacy-Focused",
    description: "We prioritize your privacy. Our platform does not track your search history or store any personal data. You can search with confidence, knowing that your privacy is protected.",
    icon: <FaShieldAlt />
  },
  {
    title: "Multi-Engine Search",
    description: "Our unique multi-engine search capability allows you to get diverse perspectives on any topic. By aggregating results from various search engines, we help you see the bigger picture and make informed decisions.",
    icon: <FaLayerGroup />
  },
  {
    title: "Free to Use",
    description: "Enjoy all the features of our search engine finder for free. We believe that access to information should be easy and free for everyone.",
    icon: <FaDollarSign />
  }
];

const FeatureCard = ({ title, description, icon }) => (
  <div className="bg-white shadow-md overflow-hidden mb-6 transition-shadow ease-in-out hover:shadow-[0_0_30px_#FFD338] to- text-white hover:text-black rounded-lg duration-300">
    <div className="px-6 py-4">
      <div className="flex justify-center mb-4">
        <div className="text-3xl text-textSoft">{icon}</div>
      </div>
      <div className="font-bold text-bgDark text-center text-xl mb-2">{title}</div>
      <p className="text-gray-700 text-base text-center">
        {description}
      </p>
    </div>
  </div>
);

const FeaturesList = () => (
  <div className="p-4 container mx-auto my-20">
    <h1 className="text-center text-4xl font-bold mb-12 text-white">Why Choose Us?</h1>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {features.map((feature, index) => (
        <FeatureCard key={index} title={feature.title} description={feature.description} icon={feature.icon} />
      ))}
    </div>
  </div>
);

export default FeaturesList;
