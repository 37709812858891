
import logoImage from "../images/logo.png";
import { footerLinks, socialLinks } from "../constants";

const FooterSection = () => {

    const year = new Date().getFullYear();

  return (
    <div className="border-t-[1px] border-t-[#3F3E45] bg-bgDark text-white">
    <section className={`container mx-auto flex justify-center items-center sm:py-16 py-6 flex-col`}>
      <div className={`flex justify-center items-start md:flex-row flex-col mb-8 w-full`}>
        <div className="flex-[1] flex flex-col justify-start mr-10">
          <img
            src={logoImage}
            alt="footer"
            className="w-[266px] h-[72.14px] object-contain"
          />
           <p className="text-2xl text-white font-medium">
              Search Engine<span className="font-bold text-textSoft"> Finder</span>
            </p>
        </div>

        <div className="flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0 mt-10">
          {footerLinks.map((footerlink) => (
            <div
              key={footerlink.title}
              className={`flex flex-col ss:my-0 my-4 min-w-[150px]`}
            >
              <h4 className="font-poppins font-medium text-[18px] leading-[27px] text-white">
                {footerlink.title}
              </h4>
              <ul className="list-none mt-4">
                {footerlink.links.map((link, index) => (
                  <a
                    key={link.name}
                    href={link.link} 
                    className={`font-poppins font-normal flex text-[16px] leading-[24px] text-dimWhite hover:text-secondary cursor-pointer ${
                      index !== footerlink.links.length - 1 ? "mb-4" : "mb-0"
                    }`}
                  >
                    {link.name}
                  </a>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full flex justify-between items-center md:flex-row flex-col pt-8 border-t-[1px] border-t-[#3F3E45]">
      <p className="py-4 text-sm sm:text-base">Copyright {year} &copy; UZR Technology</p>

        <div className="flex gap-4 pt-4 text-2xl sm:text-3xl">
          {socialLinks.map((social, index) => (
            <a 
            key={index} 
            href={social.link} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="hover:text-textSoft transition-colors duration-300"
        >
            <social.icon />
        </a>
          ))}
        </div>
      </div>
    </section>
    </div>
  );
};

export default FooterSection;
