import React from "react";

const SearchInput = ({ query, setQuery }) => (
  <div className="w-full md:w-1/2 mx-auto px-4 md:px-0">
    <input
      type="text"
      className="border border-textSoft h-12 rounded-lg p-2 w-full"
      placeholder="Enter your query..."
      value={query}
      onChange={(e) => setQuery(e.target.value)}
    />
  </div>
);

export default SearchInput;
