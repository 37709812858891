import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        "http://localhost/learnPhp/registration.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        toast.success("User Registered Successfully!!", {
          position: "top-center",
        });
        navigate("/signin");
      } else {
        console.error("Error:", data.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="absolute top-4 left-4 z-50">
        <button onClick={handleBack} className="text-white">
          <FaArrowLeft size={24} />
        </button>
      </div>
      <div className="flex w-[80%] mx-auto bg-white items-center">
        <div className="w-1/2">
          <div
            className={`absolute top-0 right-1/2 h-full w-1/2 overflow-hidden rounded-r-[25%] z-30`}
          >
            <div
              className={`bg-bgDark text-white absolute top-0 left-[-0%] h-full w-[100%]`}
            >
              <div
                className={`h-full flex flex-col items-center justify-center p-8 text-center`}
              >
                <h1 className="text-4xl font-bold mb-4 text-textSoft">
                  Create Your Account
                </h1>
                <p className="text-lg mb-8 max-w-prose">
                  Join us today and unlock a tailored search experience.
                  Register now to save your favorite results, track your
                  searches, and enjoy seamless access across all devices. Fill
                  in the required fields below to get started. Already have an
                  account? Sign in here.
                </p>
                <Link to={"/signin"}>
                  <button className="btn border-white">SIGN IN</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/2">
          <div className="p-20">
            <div className="">
              <form className="text-center p-6" onSubmit={handleSubmit}>
                <h1 className="text-3xl font-semibold mb-4">Create Account</h1>
                <span className="text-sm mb-4">
                  or use your email for sign in
                </span>

                <label htmlFor="name" className="block text-left mb-2">
                  Full Name
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Full Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="input mb-2 w-full h-12 px-3 py-3 border border-gray-300 rounded"
                  />
                </label>

                <label htmlFor="email" className="block text-left mb-2">
                  Email
                  <input
                    type="email"
                    placeholder="Email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="input mb-2 w-full h-12 px-3 py-3 border border-gray-300 rounded"
                  />
                </label>

                <label htmlFor="password" className="block text-left mb-2">
                  Password
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    className="input mb-2 w-full h-12 px-3 py-3 border border-gray-300 rounded"
                  />
                </label>

                <button className="btn w-full py-2 rounded" type="submit">
                  Sign Up
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
