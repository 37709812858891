import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./pages/Profile";
import Favorites from "./pages/Favorites";
import LandingPage from "./pages/LandingPage";
import PrivateRoute from "./components/PrivateRoute";



function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/" element={<LandingPage/>} />
          <Route path="/home" element={<PrivateRoute element={<Home />} />} />
          <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
          <Route path="/favorites" element={<PrivateRoute element={<Favorites />} />} />
          {/* <Route path="/tiktokhome" element={<PrivateRoute element={<TikTokHome />} />} /> */}
      
        </Routes>
      </HashRouter>

      <ToastContainer autoClose={2000} />
    </>
  );
}

export default App;
