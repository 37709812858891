import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    margin: "0 auto",
    textAlign: "center",
    padding: "0.5rem",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "0.5rem",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    borderColor: "#FFD338",
    fontSize: "1rem",
    backgroundColor: "#f8f9fa",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#0d0c22",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "white",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "white",
    ":hover": {
      backgroundColor: "#2d2b42",
      color: "white",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#0d0c22",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#0d0c22",
  }),
};

const EngineSelect = ({ selectedOptions, handleChange, options }) => (
  <div className="w-full md:w-1/2 mx-auto mt-4 px-4 md:px-0">
    <Select
      id="search-engines"
      isMulti
      value={selectedOptions}
      onChange={handleChange}
      options={options}
      styles={customStyles}
      placeholder="Select search engines..."
    />
  </div>
);

export default EngineSelect;
