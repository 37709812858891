import React from "react";

const TabNavigation = ({ activeTabs, activeTab, handleTabClick, options }) => (
  <div className="mt-4">
    {activeTabs.length > 0 && (
      <div className="flex justify-center space-x-4">
        {activeTabs.map((tab) => (
          <div
            key={tab}
            className={`py-2 px-6 font-medium rounded-lg cursor-pointer ${
              activeTab === tab
                ? "bg-textSoft text-bgDark"
                : "border border-textSoft text-textSoft hover:bg-bgSoft"
            }`}
            onClick={() => handleTabClick(tab)}
          >
            {options.find((option) => option.value === tab).label}
          </div>
        ))}
      </div>
    )}
  </div>
);

export default TabNavigation;
