
import React from "react";
import minusIcon from "../images/icon-minus.svg";
import { useState } from "react";
import plusIcon from "../images/icon-plus.svg";

import { useAutoAnimate } from "@formkit/auto-animate/react";


export default function Accordion(props) {
  const [animationParent] = useAutoAnimate();
  const [isAccordionOpen, setAccordion] = useState(
    props.isAccordionOpen || false
  );
  function toggleAccordion() {
    setAccordion(!isAccordionOpen);
  }
  return (
    <div ref={animationParent} className="flex flex-col gap-4 py-4 text-bgDark">
      {/* q */}
      <p
        onClick={toggleAccordion}
        className=" flex justify-between gap-2  sm:text-lg font-semibold cursor-pointer "
      >
        <span>{props.question}</span>
        {isAccordionOpen ? (
          <img src={minusIcon} alt="minus-icon" className=" h-6 w-auto" />
        ) : (
          <img src={plusIcon} alt="plus-icon" className=" h-6 w-auto" />
        )}
      </p>
      {/* ans */}
      {isAccordionOpen && (
        <p className="text-sm sm:text-base text-gray-400 ">{props.answer}</p>
      )}
    </div>
  );
}