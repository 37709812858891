import React from "react";
import Line1 from "../images/line.png";
import Line2 from "../images/line-bottom.png";

const HowItWorks = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-center text-4xl font-bold mb-12 text-white">
        HOW IT WORKS
      </h1>
      <div className="flex flex-col md:flex-row items-center justify-around pt-10 space-y-10 md:space-y-0 md:space-x-10">
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center h-16 w-16 border border-white text-white text-2xl rounded-full shadow-lg mb-4">
            1
          </div>
          <p className="text-center text-lg text-textSoft">Enter Your Query</p>
          <p className="text-center text-sm text-white">
            Start by typing in the search query or keywords that you are
            interested in.
          </p>
        </div>
        <div className="flex-grow flex justify-center items-center relative">
          <img src={Line1} alt="line" className="h-6 w-32 max-w-xs md:w-full" />
        </div>
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center h-16 w-16 bg-white text-bgDark text-2xl rounded-full shadow-lg mb-4">
            2
          </div>
          <p className="text-center text-lg text-textSoft">
            Select a Search Engine
          </p>
          <p className="text-center text-sm text-white">
            Choose your preferred search engine from the available options to
            get customized results.
          </p>
        </div>
        <div className="flex-grow flex justify-center items-center relative">
          <img src={Line2} alt="line" className="h-6 w-32 max-w-xs md:w-full" />
        </div>
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center h-16 w-16 border border-white text-white text-2xl rounded-full shadow-lg mb-4">
            3
          </div>
          <p className="text-center text-lg text-textSoft">
            Set Number of Results
          </p>
          <p className="text-center text-sm text-white">
            Define how many results you would like to see for your search query.
          </p>
        </div>
        <div className="flex-grow flex justify-center items-center relative">
          <img src={Line1} alt="line" className="h-6 w-32 max-w-xs md:w-full" />
        </div>
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center h-16 w-16 bg-white text-bgDark text-2xl rounded-full shadow-lg mb-4">
            4
          </div>
          <p className="text-center text-lg text-textSoft">View Results</p>
          <p className="text-center text-sm text-white">
            Click on the search button to view the results based on your query,
            chosen search engine, and the number of results specified.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
