import React, { useEffect, useRef, useState } from "react";

const Stepper = ({ steps, currentStep }) => {
  const [newStep, setNewStep] = useState([]);
  const stepRef = useRef();

  const updateStep = (stepNumber, steps) => {
    const newSteps = [...steps];
    let count = 0;

    while (count < newSteps.length) {
      if (count === stepNumber) {
        // Current step
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: true,
        };
        count++;
      } else if (count < stepNumber) {
        // Completed steps
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      } else {
        // Pending steps
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }

    // Ensure the last step is ticked if it's the final step
    if (stepNumber >= newSteps.length - 1) {
      newSteps[newSteps.length - 1] = {
        ...newSteps[newSteps.length - 1],
        completed: true,
      };
    }

    return newSteps;
  };

  useEffect(() => {
    const stepState = steps.map((step, index) =>
      Object.assign(
        {},
        {
          description: step,
          completed: false,
          highlighted: index === 0 ? true : false,
          selected: index === 0 ? true : false,
        }
      )
    );

    stepRef.current = stepState;
    const current = updateStep(currentStep - 1, stepRef.current);
    setNewStep(current);
  }, [steps, currentStep]);

  const displaySteps = newStep.map((step, index) => {
    return (
      <div
        key={index}
        className={
          index !== newStep.length - 1
            ? "w-full flex items-center"
            : "flex items-center"
        }
      >
        <div className="relative flex flex-col items-center text-textSoft">
          <div
            className={`rounded-full transition duration-500 ease-in-out w-12 h-12 flex justify-center border-2 border-gray-300 items-center py-3${
              step.selected
                ? " bg-bgDark text-white font-bold border border-textSoft"
                : ""
            }`}
          >
            {/* Stepper Number */}
            {step.completed ? (
              <span className="text-white font-bold text-xl bg-bgDark rounded-full flex items-center justify-center w-12 h-12 border border-textSoft">
                &#10003;
              </span>
            ) : (
              <span className="text-textSoft font-bold text-xl">
                {index + 1}
              </span>
            )}
          </div>

          <div
            className={`absolute top-0 text-center mt-16 w-32 text-sm font-medium uppercase ${
              step.highlighted ? "text-textSoft" : "text-gray-400"
            }`}
          >
            {/* Stepper Description */}
            {step.description}
          </div>
        </div>

        <div
          className={`flex-auto ${
            index !== newStep.length - 1 ? "border-t-2" : ""
          } transition duration-500 ease-in-out ${
            step.completed ? "border-textSoft" : "border-gray-300"
          }`}
        >
          {/* Stepper Line */}
        </div>
      </div>
    );
  });

  return (
    <div className="p-4 flex justify-between items-center shadow-md w-1/2 mx-auto">
      {displaySteps}
    </div>
  );
};

export default Stepper;
