import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../images/logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const storedUserName = localStorage.getItem("userName");
  const userName = storedUserName
    ? storedUserName.charAt(0).toUpperCase() + storedUserName.slice(1)
    : "";
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    axios
      .get("http://localhost/learnPhp/logout.php")
      .then((response) => {
        if (response.data.message) {
          console.log(response.data.message);
          localStorage.clear();
          navigate("/signin");
        } else {
          console.error("Logout failed");
        }
      })
      .catch((error) => {
        console.error("There was an error logging out!", error);
      });
  };

  // Get the first letter of the username
  const firstLetter = userName ? userName.charAt(0).toUpperCase() : "";

  return (
    <nav className="bg-bgDark text-white border-b border-white p-2">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-xl font-bold">
          <Link to="/">
          <div className="flex items-center gap-3">
            <img src={logoImage} alt="" className="h-16" />
            <p className="text-2xl text-white font-medium">
              Search Engine<span className="font-bold text-textSoft"> Finder</span>
            </p>
          </div>
           
          </Link>
        </div>
        <div className="relative">
      <button onClick={toggleDropdown} className="focus:outline-none">
        <div className="flex items-center gap-2">
          <div className="w-10 h-10 flex items-center justify-center rounded-full bg-textSoft text-bgDark text-lg font-bold">
            {firstLetter}
          </div>
          <div className="hidden md:block">
          {userName}
          </div>
      
        </div>
      </button>
      {isOpen && (
        <div className="absolute mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-20">
          <div className="px-4 py-2 flex flex-col items-center justify-center text-center">
            <div className="w-10 h-10 flex items-center justify-center rounded-full bg-bgDark text-bgDark text-lg font-bold">
            <img src={logoImage} alt="Logo"/>
            </div>
          
            <div>
           
            </div>
          </div>
          <hr className="my-2"/>
          <Link
            to="/profile"
            className="block px-4 py-2 text-bgDark hover:bg-gray-100"
          >
            Profile
          </Link>
        
          <Link
            to="/favorites"
            className="block px-4 py-2 text-bgDark hover:bg-gray-100"
          >
            Favorites
          </Link>
          <button
            onClick={handleLogout}
            className="block w-full text-left px-4 py-2 text-bgDark hover:bg-gray-100"
          >
            Logout
          </button>
        </div>
      )}
    </div>
      </div>
    </nav>
  );
};

export default Navbar;
