import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("http://localhost/learnPhp/login.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log("data", data);

      if (data.error) {
        toast.error(data.error || "Login failed. Please try again.", {
          position: "top-center",
        });
      } else {
        toast.success(data.message, {
          position: "top-center",
        });

        // Store user data in local storage upon successful login
        localStorage.setItem("userEmail", data?.user?.email);
        localStorage.setItem("userName", data?.user?.name);
        localStorage.setItem("id", data?.user?.id);

        navigate("/");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: "top-center",
      });
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="">
      <div className="flex w-[80%] mx-auto min-h-screen bg-white items-center">
        <div className="absolute top-4 left-4 z-50">
          <button onClick={handleBack} className="text-bgDark">
            <FaArrowLeft size={24} />
          </button>
        </div>
        <div className="w-1/2">
          <div className="overflow-hidden p-20">
            <div className="">
              <form className="text-center p-6" onSubmit={handleSubmit}>
                <h1 className="text-3xl font-semibold mb-4">Sign In</h1>
                <span className="text-sm mb-4">
                  or use your email for sign in
                </span>

                <label htmlFor="email" className="block text-left mb-2">
                  Email
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    className="input mb-2 w-full h-12 px-3 py-3 border border-gray-300 rounded"
                  />
                </label>

                <label htmlFor="password" className="block text-left mb-2">
                  Password
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    className="input mb-2 w-full px-3 h-12 py-3 border border-gray-300 rounded"
                  />
                </label>

                <button className="btn w-full py-2 rounded">Sign In</button>
              </form>
            </div>
          </div>
        </div>

        <div className={`toggle-container`}>
          <div className={`toggle`}>
            <div className={`toggle-panel toggle-right`}>
              <h1 className="text-4xl font-bold mb-4 text-textSoft">
                Welcome Back!
              </h1>
              <p className="text-lg mb-8 max-w-prose">
                Access your personalized dashboard and manage your favorites by
                logging in. Enter your email and password to continue. Don’t
                have an account? Sign up to explore more features and streamline
                your search experience.
              </p>
              <Link to={"/signup"}>
                <button className="btn border-white">SIGN UP</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
