import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/FooterSection";

const Profile = () => {
  const userName = localStorage.getItem("userName");
  const userEmail = localStorage.getItem("userEmail");
  const firstLetter = userName ? userName.charAt(0).toUpperCase() : "";

  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-bgDark">
        <div className="container mx-auto py-10">
          <h1 className="text-center text-4xl font-bold mb-12 text-white">
            User Profile
          </h1>

          <div className="max-w-md mx-auto  bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="flex items-center  justify-center w-12 h-12 rounded-full bg-textSoft text-bgDark text-lg font-bold mx-auto">
              {firstLetter}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">
                Name:
              </label>
              <span className="text-gray-700">{userName}</span>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">
                Email:
              </label>
              <span className="text-gray-700">{userEmail}</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
