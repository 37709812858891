import React from "react";
import Accordion from "./Accordion";
import { faqs } from "../data/data";
import starIcon from "../images/icon-star.svg";

const FAQSection = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <section className="relative bg-white transition-all mx-auto  max-w-xs sm:max-w-2xl rounded-lg flex flex-col gap-4 mt-40 sm:mt-24 p-5">
        <h1 className="flex gap-3 items-center">
          {/* image */}
          <img src={starIcon} alt="star-icon" className=" h-6  w-auto" />
          <span className="font-bold text-4xl text-bgDark">FAQs</span>
        </h1>
        <div className="flex flex-col gap-4 divide-y">
          {faqs.map((d, i) => (
            <Accordion
              answer={d.answer}
              question={d.question}
              isAccordionOpen={d.isAccordionOpen}
              key={i}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default FAQSection;
