
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Get Started",
        link: "https://www.hoobank.com/how-it-works/",
      },
      {
        name: "Profile",
        link: "https://www.hoobank.com/create/",
      },
      {
        name: "Favourites",
        link: "https://www.hoobank.com/explore/",
      },
      {
        name: "How its works",
        link: "https://www.hoobank.com/terms-and-services/",
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        name: "Policy",
        link: "https://www.hoobank.com/help-center/",
      },
      {
        name: "Terms",
        link: "https://www.hoobank.com/partners/",
      },
   
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Our Partner",
        link: "https://www.hoobank.com/our-partner/",
      },
      {
        name: "Become a Partner",
        link: "https://www.hoobank.com/become-a-partner/",
      },
    ],
  },
];


export const socialLinks = [
    { name: "Facebook", icon: FaFacebook, link: "https://www.facebook.com/uzrtech/" },
    { name: "Instagram", icon: FaInstagram, link: "https://www.instagram.com/uzrtech/" },
    { name: "LinkedIn", icon: FaLinkedin, link: "https://pk.linkedin.com/company/uzrtech" },
];
